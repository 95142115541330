import {
  Modal as ChakraModal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  VStack,
} from '@chakra-ui/react';
import { CustomButton } from 'components/CustomButton';
import { type MetricOption } from 'types/addPrediction';
import { type EntityType } from 'types/futures';
import type { League, Player, Team } from 'types/sportsdata';
import { setEntityLabel } from 'utils/addPred';

type ModalProps = {
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
  submitFuture: () => void;
  league: League;
  future: MetricOption;
  seasonYear: string;
  entityType: EntityType;
  selectedTeams: Team[];
  selectedPlayers: Player[];
};

export const Modal = ({
  setShowModal,
  submitFuture,
  league,
  future,
  seasonYear,
  entityType,
  selectedTeams,
  selectedPlayers,
}: ModalProps) => {
  const handleClose = () => {
    setShowModal(false);
  };

  const handleSubmit = () => {
    submitFuture();
    setShowModal(false);
  };

  return (
    <ChakraModal isOpen={true} onClose={handleClose} isCentered size="xl">
      <ModalOverlay
        bg="blackAlpha.300"
        backdropFilter="blur(10px) hue-rotate(90deg)"
      />
      <ModalContent mx={4} bg="neutral.500" borderRadius="xl" boxShadow="xl">
        <ModalHeader borderBottomWidth="1px" borderColor="neutral.700">
          <Text fontSize="xl" fontWeight="bold" color="text.primary">
            Future Results Preview
          </Text>
        </ModalHeader>
        <ModalCloseButton color="text.primary" />

        <ModalBody py={6}>
          <VStack spacing={6} align="stretch">
            <VStack align="stretch" spacing={2}>
              <Text fontWeight="medium" color="text.secondary">
                Future
              </Text>
              <Text fontSize="lg" color="text.primary" fontWeight="semibold">
                {league.toLocaleUpperCase()} {seasonYear} {future.label}
              </Text>
            </VStack>

            <VStack align="stretch" spacing={2}>
              <Text fontWeight="medium" color="text.secondary">
                Type
              </Text>
              <Text fontSize="lg" color="text.primary" fontWeight="semibold">
                {entityType}
              </Text>
            </VStack>
            {entityType == 'team' && (
              <VStack align="stretch" spacing={2}>
                <Text fontWeight="medium" color="text.secondary">
                  Teams:
                </Text>
                {selectedTeams.length > 0 ? (
                  <Text
                    fontSize="lg"
                    color="text.primary"
                    fontWeight="semibold"
                  >
                    {selectedTeams.map(
                      (team, index) =>
                        (index !== 0 ? ', ' : '') + setEntityLabel(team)
                    )}
                  </Text>
                ) : (
                  <Text
                    fontSize="lg"
                    color="text.primary"
                    fontWeight="semibold"
                  >
                    No applicable teams
                  </Text>
                )}
              </VStack>
            )}
            {entityType == 'player' && (
              <VStack align="stretch" spacing={2}>
                <Text fontWeight="medium" color="text.secondary">
                  Players:
                </Text>
                <Text fontSize="lg" color="text.primary" fontWeight="semibold">
                  {selectedPlayers.map(
                    (player, index) =>
                      (index !== 0 ? ', ' : '') + setEntityLabel(player)
                  )}
                </Text>
              </VStack>
            )}
          </VStack>
        </ModalBody>

        <ModalFooter borderTopWidth="1px" borderColor="neutral.700" gap={3}>
          <CustomButton
            buttonText="Cancel"
            variant="outlined"
            onClick={handleClose}
            accessibilityLabel="cancel-future"
          />
          <CustomButton
            buttonText="Finish Future"
            onClick={handleSubmit}
            accessibilityLabel="finish-future"
          />
        </ModalFooter>
      </ModalContent>
    </ChakraModal>
  );
};
