import axios from 'axios';
import { getApiUrls } from 'config/api';
import type { EntityType, PostFutureResults } from 'types/futures';
import type { League } from 'types/sportsdata';

const { API_URL } = getApiUrls();

export const getFuturesByLeague = async (
  league: League,
  entityType: EntityType
) => {
  const url = `${API_URL}/sportsdata/${league}/futures/${entityType}`;
  const { data } = await axios.get(url);
  return data.object;
};

export const postFuture = async (body: PostFutureResults) => {
  const { data } = await axios.post(`${API_URL}/finishFuturePredictions`, body);
  return data;
};

export const getActiveSeasons = async () => {
  const url = `${API_URL}/sportsdata/activeSeasons`;
  const { data } = await axios.get(url);
  return data.object;
};
