import { type Season } from 'types/sportsdata';

export const MLB_SEASONS: Season[] = [
  {
    seasonId: '2025PRE',
    seasonYear: '2025',
    seasonEndDate: '2025-03-27T00:00:00Z',
    label: '2025 Pre Season',
    league: 'mlb',
  },
  {
    seasonId: '2025REG',
    seasonYear: '2025',
    seasonEndDate: '2025-09-30T00:00:00Z',
    label: '2024 Regular Season',
    league: 'mlb',
  },
  {
    seasonId: '2025POST',
    seasonYear: '2025',
    seasonEndDate: '2025-11-08T00:00:00Z',
    label: '2025 Post Season',
    league: 'mlb',
  },
];

export const CURRENT_MLB_FUTURES_SEASON: Season = {
  seasonId: '2025',
  seasonYear: '2025',
  seasonEndDate: '2025-11-08T00:00:00Z',
  label: 'MLB 2025 Season',
  league: 'mlb',
};
