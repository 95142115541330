import { useState } from 'react';
import {
  Image,
  HStack,
  Heading,
  Card,
  Text,
  Badge,
  Flex,
  Table,
  Thead,
  Th,
  Tbody,
  Tabs,
  TabList,
  Tab,
  Tr,
  Td,
  Avatar,
  VStack,
} from '@chakra-ui/react';
import { useParams } from 'react-router-dom';
import { type GetCommunityUserOption } from 'api/community';
import { CommunityPlaceholder } from 'assets';
import { FullScreenSpinner, Spinner } from 'components/Spinner';
import { useCommunityDetails } from 'hooks/useCommunityDetails';
import { CommunityUserActions } from 'screens/CommunityDetails/community-user-actions';
import type { CollegeTeam, Team } from 'types/sportsdata';
import type { User, UserSummary } from 'types/user';

export const CommunityDetails: React.FC = () => {
  const { communityId } = useParams();
  //const { firebaseUser } = useAuth();
  const [userFilter, setUserFilter] = useState<GetCommunityUserOption>('users');
  const { community, communityQuery, usersResult, usersQuery } =
    useCommunityDetails({
      communityId: communityId ?? '',
      userFilter,
    });

  if (!community || communityQuery.isLoading) {
    return <FullScreenSpinner />;
  }

  //TODO: include this when admin matching is implemented
  // const isUserAdmin = community.adminData
  //   .map((u) => u.userId)
  //   .includes(firebaseUser?.uid ?? '');

  const tabSelectedStyle = {
    bg: 'accent.400',
    fontWeight: '500',
  };

  return (
    <HStack h="full" px="20px" align="flex-start" flex={1}>
      <VStack padding="20px" gap="40px" flex="50%" maxW="50%">
        <HStack>
          <Image
            src={community.logoUrl ?? CommunityPlaceholder}
            boxSize="100px"
          />
          <Heading>{community.title}</Heading>
        </HStack>
        <Card
          bg="neutral.500"
          padding="20px"
          borderColor="accent.100"
          borderWidth="1px"
          maxW="100%"
          maxH="100%"
        >
          <Text>
            <b>Description:</b> {community.description}
          </Text>
          <Text>
            <b>Number of Members:</b> {community.numberOfMembers}
          </Text>
          <Text>
            <b>Privacy:</b> {community.privacy}
          </Text>
          <Text>
            <b>Verified?:</b> {community.verifiedCommunity ? 'Yes' : 'No'}
          </Text>
          <Text>
            <b>Created:</b> {new Date(community.createdAt).toLocaleDateString()}
          </Text>
          <Text>
            <b>Teams:</b>
            {community.teamTags.length === 0 && <Text>No tagged teams</Text>}
          </Text>
          <Flex gap="8px" wrap="wrap">
            {community.teamTags.map((tag: Team) =>
              isCollegeTeam(tag) ? (
                <Badge
                  key={tag.GlobalTeamID}
                  border={`1px solid #00000}`}
                  padding="5px"
                >{`${tag.School} ${tag.Name}`}</Badge>
              ) : (
                <Badge
                  key={tag.GlobalTeamID}
                  bg={`#${tag.PrimaryColor}`}
                  color={`#${tag.SecondaryColor}`}
                  border={`1px solid #${tag.TertiaryColor ?? 'FFFFFF'}`}
                  padding="5px"
                >
                  {`${tag.City ?? ''} ${tag.Name}`.trim()}
                </Badge>
              )
            )}
          </Flex>
        </Card>
      </VStack>
      <VStack padding="30px" align="center" flex="60%">
        <Tabs onChange={(index) => setUserFilter(getTabFilterIndex(index))}>
          <TabList>
            <Tab _selected={tabSelectedStyle}>Current Users</Tab>
            <Tab _selected={tabSelectedStyle}>Invited Users</Tab>
            {community.privacy === 'exclusive' && (
              <Tab _selected={tabSelectedStyle}>Join Requests</Tab>
            )}
            <Tab _selected={tabSelectedStyle}>Blocked Users</Tab>
          </TabList>
        </Tabs>
        {/* 
        //TODO: include this when admin matching is implemented
        {!isUserAdmin && (
          <Text color="accent.100">
            You are not an admin of this community. User actions will not be
            available.
          </Text>) */}
        {!usersResult || usersQuery.isFetching ? (
          <Spinner />
        ) : usersResult.users.length === 0 ? (
          <Text>No users found</Text>
        ) : (
          <Table w="100%" mb="20px" size="sm">
            <Thead w="full">
              <Tr w="full">
                <Th color="text.primary" w="full">
                  Name
                </Th>
                <Th color="text.primary">Username</Th>
                {/* TODO: Add '&& isUserAdmin' below to turn off admin actions for non-admins */}
                {userFilter !== 'invited' && (
                  <Th color="text.primary">Actions</Th>
                )}
              </Tr>
            </Thead>
            <Tbody w="full">
              {sortByAdmin(usersResult.users, community.adminData).map(
                (user) => (
                  <Tr key={user.userId}>
                    <Td>
                      <HStack align="center">
                        <Avatar src={user.profilePicUrl} size="xs" />
                        <Text>{`${user.firstName} ${user.lastName}`}</Text>
                        {community.adminData
                          .map((data) => data.userId)
                          .includes(user.userId) && <Badge>Admin</Badge>}
                      </HStack>
                    </Td>
                    <Td>{`@${user.username}`}</Td>
                    {/* TODO: Add '&& isUserAdmin' below to turn off admin actions for non-admins */}
                    {userFilter !== 'invited' && (
                      <Td>
                        <CommunityUserActions
                          user={user}
                          adminData={community.adminData}
                          userFilter={userFilter}
                          communityId={communityId ?? ''}
                        />
                      </Td>
                    )}
                  </Tr>
                )
              )}
            </Tbody>
          </Table>
        )}
      </VStack>
    </HStack>
  );
};

const isCollegeTeam = (team: Team): team is CollegeTeam => {
  return 'School' in team;
};

const getTabFilterIndex = (index: number) => {
  switch (index) {
    case 0:
      return 'users';
    case 1:
      return 'invited';
    case 2:
      return 'requests';
    case 3:
      return 'blocked';
    default:
      return 'users';
  }
};

const sortByAdmin = (users: User[], adminData: UserSummary[]) => {
  const adminIds = adminData.map((data) => data.userId);
  return users.sort((a, b) => {
    if (adminIds.includes(a.userId) && !adminIds.includes(b.userId)) {
      return -1;
    } else if (!adminIds.includes(a.userId) && adminIds.includes(b.userId)) {
      return 1;
    } else {
      return 0;
    }
  });
};
