import React, { useCallback, useEffect, useState } from 'react';

import { Box } from '@chakra-ui/react';
import { MultiselectDropdown } from './MultiselectDropdown';
import usePlayers from 'hooks/usePlayers';
import useTeams from 'hooks/useTeams';
import { type EntityType } from 'types/futures';
import type { League, Team, Player } from 'types/sportsdata';
import { setEntityLabel, setTeamLabel } from 'utils/addPred';
import { isNflPlayer } from 'utils/player';
import { isCollegeTeam } from 'utils/teams';

export type EntitySearchProps = {
  selectedTeams: Team[];
  setSelectedTeams: (entities: Team[]) => void;
  selectedPlayers: Player[];
  setSelectedPlayers: (entities: Player[]) => void;
  league?: League;
  entityType: EntityType;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  inputRef: React.MutableRefObject<any>;
};

export const EntitySearch: React.FC<EntitySearchProps> = ({
  selectedTeams,
  setSelectedTeams,
  selectedPlayers,
  setSelectedPlayers,
  league,
  entityType,
  inputRef,
}) => {
  // Load Players for each league
  const { players: nbaPlayers, playersLoading: nbaPlayersLoading } =
    usePlayers('nba');
  const { players: cbbPlayers, playersLoading: cbbPlayersLoading } =
    usePlayers('cbb');
  const { players: mlbPlayers, playersLoading: mlbPlayersLoading } =
    usePlayers('mlb');
  const { players: nflPlayers, playersLoading: nflPlayersLoading } =
    usePlayers('nfl');
  const { players: nhlPlayers, playersLoading: nhlPlayersLoading } =
    usePlayers('nhl');
  const { players: cfbPlayers, playersLoading: cfbPlayersLoading } =
    usePlayers('cfb');

  // Load Teams for each league
  const { teams: nbaTeams, teamsLoading: nbaTeamsLoading } = useTeams('nba');
  const { teams: cbbTeams, teamsLoading: cbbTeamsLoading } = useTeams('cbb');
  const { teams: mlbTeams, teamsLoading: mlbTeamsLoading } = useTeams('mlb');
  const { teams: nflTeams, teamsLoading: nflTeamsLoading } = useTeams('nfl');
  const { teams: cfbTeams, teamsLoading: cfbTeamsLoading } = useTeams('cfb');
  const { teams: wnbaTeams, teamsLoading: wnbaTeamsLoading } = useTeams('wnba');
  const { teams: cwbbTeams, teamsLoading: cwbbTeamsLoading } = useTeams('cwbb');
  const { teams: nhlTeams, teamsLoading: nhlTeamsLoading } = useTeams('nhl');

  const [playerArray, setPlayerArray] = useState<Player[]>([]);
  const [teamArray, setTeamArray] = useState<Team[]>([]);

  const teamsLoading = useCallback(() => {
    return (
      nbaTeamsLoading ||
      mlbTeamsLoading ||
      nflTeamsLoading ||
      cbbTeamsLoading ||
      cfbTeamsLoading ||
      wnbaTeamsLoading ||
      cwbbTeamsLoading ||
      nhlTeamsLoading
    );
  }, [
    nbaTeamsLoading,
    mlbTeamsLoading,
    nflTeamsLoading,
    cbbTeamsLoading,
    cfbTeamsLoading,
    cwbbTeamsLoading,
    wnbaTeamsLoading,
    nhlTeamsLoading,
  ]);

  const playersLoading = useCallback(() => {
    return (
      nbaPlayersLoading ||
      cbbPlayersLoading ||
      mlbPlayersLoading ||
      nflPlayersLoading ||
      nhlPlayersLoading ||
      cfbPlayersLoading
    );
  }, [
    nbaPlayersLoading,
    cbbPlayersLoading,
    mlbPlayersLoading,
    nflPlayersLoading,
    cfbPlayersLoading,
    nhlPlayersLoading,
  ]);

  useEffect(() => {
    switch (league) {
      case 'mlb':
        setTeamArray(mlbTeams || []);
        break;
      case 'nba':
        setTeamArray(nbaTeams || []);
        break;
      case 'nfl':
        setTeamArray(nflTeams || []);
        break;
      case 'cbb':
        setTeamArray(cbbTeams || []);
        break;
      case 'cfb':
        setTeamArray(cfbTeams || []);
        break;
      case 'wnba':
        setTeamArray(wnbaTeams || []);
        break;
      case 'cwbb':
        setTeamArray(cwbbTeams || []);
        break;
      case 'nhl':
        setTeamArray(nhlTeams || []);
        break;
      default:
        setTeamArray([]);
        break;
    }
  }, [
    nflTeams,
    nbaTeams,
    mlbTeams,
    cbbTeams,
    cfbTeams,
    wnbaTeams,
    cwbbTeams,
    nhlTeams,
    league,
  ]);

  useEffect(() => {
    switch (league) {
      case 'mlb':
        setPlayerArray(mlbPlayers || []);
        break;
      case 'nba':
        setPlayerArray(nbaPlayers || []);
        break;
      case 'nfl':
        setPlayerArray(nflPlayers || []);
        break;
      case 'cbb':
        setPlayerArray(cbbPlayers || []);
        break;
      case 'nhl':
        setPlayerArray(nhlPlayers || []);
        break;
      case 'cfb':
        setPlayerArray(cfbPlayers || []);
        break;
      default:
        setPlayerArray([]);
    }
  }, [
    nflPlayers,
    nbaPlayers,
    cbbPlayers,
    mlbPlayers,
    cfbPlayers,
    nhlPlayers,
    league,
  ]);

  // once admin is updated, renderTeamItem and renderPlayerItem from renderItems can be used
  const renderTeamItem = (item: Team) => {
    if (isCollegeTeam(item)) {
      return <Box color={'#000000'}>{setTeamLabel(item)}</Box>;
    }
    return <Box color={'#000000'}>{`${item.City} ${item.Name}`}</Box>;
  };

  const renderPlayerItem = (item: Player) => {
    const playerLabel = isNflPlayer(item)
      ? `${item.FirstName} ${item.LastName} #${item.Number} [${item.Team}]`
      : `${item.FirstName} ${item.LastName} #${item.Jersey} [${item.Team}]`;
    return <Box color={'#000000'}>{playerLabel}</Box>;
  };

  if (entityType == 'team') {
    return (
      <Box>
        <Box as="h2">Teams</Box>
        <MultiselectDropdown
          accessibilityLabel="Entity Picker"
          placeholder="Select Team"
          items={teamArray}
          renderItem={renderTeamItem}
          onItemSelect={(item) => {
            if (item) {
              setSelectedTeams([item, ...selectedTeams]);
              const newTeamArray = teamArray.filter(
                (team) => team.GlobalTeamID !== item.GlobalTeamID
              );
              setTeamArray(newTeamArray);
            }
          }}
          onItemDeselect={(item) => {
            if (item) {
              setSelectedTeams(
                selectedTeams.filter((t) => setTeamLabel(t) !== item.label)
              );
              const deselectedTeam = selectedTeams.find(
                (t) => setTeamLabel(t) === item.label
              );
              if (deselectedTeam) {
                setTeamArray([deselectedTeam, ...teamArray]);
              }
            }
          }}
          setItemLabel={setTeamLabel}
          loading={teamsLoading()}
          inputRef={inputRef}
        />
      </Box>
    );
  }

  return (
    <Box>
      <Box as="h2">Players</Box>
      <MultiselectDropdown
        accessibilityLabel="Entity Picker"
        placeholder="Select Player"
        items={playerArray}
        renderItem={renderPlayerItem}
        onItemSelect={(item) => {
          if (item) {
            setSelectedPlayers([item, ...selectedPlayers]);
            const newPlayerArray = playerArray.filter(
              (player) => player.PlayerID !== item.PlayerID
            );
            setPlayerArray(newPlayerArray);
          }
        }}
        onItemDeselect={(item) => {
          if (item) {
            setSelectedPlayers(
              selectedPlayers.filter((t) => setEntityLabel(t) !== item.label)
            );
            const deselectedPlayer = selectedPlayers.find(
              (t) => setEntityLabel(t) === item.label
            );
            if (deselectedPlayer) {
              setPlayerArray([deselectedPlayer, ...playerArray]);
            }
          }
        }}
        setItemLabel={setEntityLabel}
        loading={playersLoading()}
        inputRef={inputRef}
      />
    </Box>
  );
};

export default EntitySearch;
