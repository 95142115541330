import './style.css';

export type ButtonProps = {
  accessibilityLabel: string;
  buttonText: string;
  icon?: React.ReactNode;
  iconPosition?: 'start' | 'end';
  variant?: 'filled' | 'outlined';
  onClick?: () => void;
  disabled?: boolean;
  isLoading?: boolean;
};

export const CustomButton = ({
  accessibilityLabel,
  buttonText,
  icon,
  iconPosition = 'start',
  variant = 'filled',
  onClick,
  disabled,
  isLoading,
}: ButtonProps) => {
  return (
    <button
      id={accessibilityLabel}
      aria-label={accessibilityLabel}
      className={`button ${variant} ${disabled ? 'button-disabled' : ''}`}
      onClick={onClick}
      disabled={disabled || isLoading}
    >
      {icon && iconPosition === 'start' && (
        <span className="icon icon-left">{icon}</span>
      )}
      <span className="button-text">{buttonText}</span>
      {icon && iconPosition === 'end' && (
        <span className="icon icon-right">{icon}</span>
      )}
    </button>
  );
};
