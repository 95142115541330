import { useEffect, useRef, useState } from 'react';
import {
  Box,
  Radio,
  RadioGroup,
  Select,
  Stack,
  Text,
  Alert,
  AlertIcon,
  Input,
} from '@chakra-ui/react';
import { FaArrowRight } from 'react-icons/fa';
import { Dropdown } from './components/Dropdown';
import EntitySearch from './components/EntitySearch';
import { Modal } from './components/Modal';
import { postFuture } from 'api/futures';
import { CustomButton } from 'components/CustomButton';
import { FullScreenSpinner } from 'components/Spinner';
import { LEAGUE_OPTIONS } from 'constants/options';
import { useSeasons } from 'hooks/useActiveSeasons';
import { useFutures } from 'hooks/useFutures';
import { type MetricOption } from 'types/addPrediction';
import type {
  EntityType,
  PostFutureResult,
  PostFutureResults,
} from 'types/futures';

import type { Team, League, Player } from 'types/sportsdata';
import { filterMetrics, setMetricLabel } from 'utils/addPred';

export const FinishFuture = () => {
  const [league, setLeague] = useState<League>();
  const [seasonYear, setSeasonYear] = useState<string>('');
  const [future, setFuture] = useState<MetricOption>();
  const [entityType, setEntityType] = useState<EntityType>('team');
  const [selectedTeams, setSelectedTeams] = useState<Team[]>([]);
  const [selectedPlayers, setSelectedPlayers] = useState<Player[]>([]);
  const [activeFutures, setActiveFutures] = useState<MetricOption[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const [showModal, setShowModal] = useState(false);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const inputRef = useRef<any>();

  const { futures, futuresLoading, futuresError } = useFutures(
    entityType,
    league
  );

  const { activeSeasonInfo, activeSeasonInfoLoading, activeSeasonInfoError } =
    useSeasons();

  const clearFuture = () => {
    setLeague(undefined);
    setSeasonYear('');
    setSelectedTeams([]);
    setSelectedPlayers([]);
    setFuture(undefined);
    setEntityType('team');
    setShowAlert(false);
    setShowSuccessAlert(false);
    inputRef.current?.resetItems();
  };

  useEffect(() => {
    setFuture(undefined);
    setSelectedTeams([]);
    setSelectedPlayers([]);
    inputRef.current?.resetItems();
  }, [league, entityType]);

  useEffect(() => {
    let newActiveFutures: MetricOption[] = [];
    if (futures) {
      newActiveFutures = futures.filter(
        (future) => future.isActive == undefined || future.isActive
      );
    }
    setActiveFutures(newActiveFutures);
  }, [futures]);

  useEffect(() => {
    if (league && activeSeasonInfo?.[league]) {
      setSeasonYear(activeSeasonInfo?.[league].futuresSeason.seasonYear);
    } else {
      setSeasonYear('');
    }
  }, [league]);

  const renderMetricItem = (item: MetricOption) => {
    return <Box color={'#000000'}>{`${item.label}`}</Box>;
  };

  const submitEnabled = () => {
    return (
      league &&
      future &&
      seasonYear &&
      seasonYear.length === 4 &&
      Number(seasonYear)
    );
  };

  const handleSendClick = () => {
    if (!submitEnabled()) {
      setShowAlert(true);
      setTimeout(() => setShowAlert(false), 3000);
      return;
    }
    setShowModal(true);
  };

  const submitFuture = async () => {
    setIsLoading(true);
    if (!league || !future || !seasonYear) {
      return;
    }
    const postBodyResult: PostFutureResult = {
      futureId: league + '#' + seasonYear + '#' + future.value,
      season: seasonYear,
      league: league!,
      type: entityType == 'team' ? 'teamFuture' : 'playerFuture',
    };
    if (entityType == 'team') {
      postBodyResult.teamResults = selectedTeams.map((team) =>
        team.GlobalTeamID.toString()
      );
    } else {
      postBodyResult.playerResults = selectedPlayers.map((player) =>
        player.PlayerID.toString()
      );
    }
    const postBody: PostFutureResults = {
      futureResults: [postBodyResult],
    };

    try {
      await postFuture(postBody);
      setShowModal(false);
      setIsLoading(false);
      setShowSuccessAlert(true);
      setTimeout(() => {
        setShowSuccessAlert(false);
        clearFuture();
      }, 3000);
    } catch (error) {
      console.error('Error finishing future.', error);
      setIsLoading(false);
    }
  };

  return (
    <>
      {isLoading ? (
        <FullScreenSpinner />
      ) : (
        <Box>
          {showAlert && (
            <Alert status="error" mb={4}>
              <AlertIcon />
              Please fill in both the league and future fields
            </Alert>
          )}
          {showSuccessAlert && (
            <Alert status="success" mb={4}>
              <AlertIcon />
              Future finish successfully triggered for all predictions!
            </Alert>
          )}
          <Box
            as="header"
            bg="light"
            border="1px solid #d0ccd9"
            p="40px 64px 24px 64px"
            boxShadow="0 10px 25px 0 rgba(0, 0, 0, 0.1)"
            display="flex"
            flexDirection="column"
          >
            <Box as="h1" fontSize="48px" lineHeight="58px" fontWeight="600">
              Finish Future
            </Box>
            <Box as="p" mt={2}>
              Finish a future to complete all predictions on that future and
              close it for the season.
            </Box>
          </Box>
          <Box display="flex" p={4} mt={6}>
            <Box
              flex="1"
              p={4}
              border="1px solid #e2e8f0"
              borderRadius="md"
              ml={4}
              backgroundColor="light"
            >
              <Box mb={8}>
                <Box as="h2">League</Box>
                <Select
                  value={league}
                  data-testid="league-select"
                  onChange={(e) => setLeague(e.target.value as League)}
                  placeholder="Select League"
                >
                  {LEAGUE_OPTIONS?.map((option) => (
                    <option key={option.value.id} value={option.value.id}>
                      {option.label}
                    </option>
                  ))}
                </Select>
              </Box>
              <Box mb={8}>
                <Box as="h2">Future Type</Box>
                <RadioGroup
                  onChange={(value) => setEntityType(value as EntityType)}
                  value={entityType}
                  mb={4}
                >
                  <Stack direction="column">
                    <Radio value="team" size="md" colorScheme="primary">
                      Team
                    </Radio>
                    <Radio value="player" size="md" colorScheme="primary">
                      Player
                    </Radio>
                  </Stack>
                </RadioGroup>
              </Box>
              <Box mb={8}>
                <Box as="h2">Future</Box>
                <Dropdown
                  accessibilityLabel="Future Picker"
                  placeholder="Select Future"
                  items={activeFutures ?? []}
                  value={future}
                  renderItem={renderMetricItem}
                  onItemSelect={setFuture}
                  onClear={() => {
                    setFuture(undefined);
                  }}
                  filterFn={filterMetrics}
                  setItemLabel={setMetricLabel}
                  loading={futuresLoading}
                  showItemsOnClick={true}
                />
                {futuresError && (
                  <Text fontSize="sm" color={'red.500'}>
                    Error getting futures. Try refreshing page.
                  </Text>
                )}
              </Box>
              <Box mb={8}>
                <Box as="h2">Year</Box>
                <Text mb={2} fontSize="sm">
                  Current year should autopopulate, change to complete a
                  previous year&apos;s future.
                </Text>
                <Input
                  placeholder={activeSeasonInfoLoading ? '...' : 'e.g. 2024'}
                  value={seasonYear}
                  onChange={(e) => {
                    setSeasonYear(e.target.value);
                  }}
                  maxLength={4}
                />
                {league && !activeSeasonInfo?.[league] && (
                  <Text fontSize="sm" color={'red.500'}>
                    Not active season for this league. Please manually enter a
                    past season.
                  </Text>
                )}
                {activeSeasonInfoError && (
                  <Text fontSize="sm" color={'red.500'}>
                    Error getting active season for this league. Please manually
                    enter.
                  </Text>
                )}
                {seasonYear &&
                  (seasonYear.length !== 4 || !Number(seasonYear)) && (
                    <Text fontSize="sm" color={'red.500'}>
                      Not a valid year
                    </Text>
                  )}
              </Box>
              <Box mb={8}>
                <EntitySearch
                  selectedTeams={selectedTeams}
                  selectedPlayers={selectedPlayers}
                  setSelectedTeams={setSelectedTeams}
                  setSelectedPlayers={setSelectedPlayers}
                  league={league}
                  entityType={entityType}
                  inputRef={inputRef}
                />
              </Box>
              {/* Submit Button */}
              <Box display="flex" justifyContent="flex-end" mb={4}>
                <CustomButton
                  accessibilityLabel="finish-future"
                  buttonText="Finish Future"
                  icon={<FaArrowRight />}
                  iconPosition="end"
                  variant="outlined"
                  onClick={handleSendClick}
                  disabled={!submitEnabled()}
                />
              </Box>
            </Box>
          </Box>
        </Box>
      )}

      {showModal && league && future && seasonYear ? (
        <Modal
          setShowModal={setShowModal}
          submitFuture={submitFuture}
          league={league}
          future={future}
          seasonYear={seasonYear}
          entityType={entityType}
          selectedTeams={selectedTeams}
          selectedPlayers={selectedPlayers}
        />
      ) : null}
    </>
  );
};
