import {
  Flex,
  Icon,
  IconButton,
  InputGroup,
  InputLeftElement,
  InputRightElement,
} from '@chakra-ui/react';
import {
  AutoComplete,
  AutoCompleteInput,
  AutoCompleteItem,
  AutoCompleteList,
  type Item,
} from '@choc-ui/chakra-autocomplete';
import { FiChevronRight, FiChevronDown, FiSearch, FiX } from 'react-icons/fi';

interface DropdownProps<T> {
  accessibilityLabel?: string;
  placeholder: string;
  items: T[];
  value?: T;
  renderItem: (item: T) => JSX.Element;
  onItemSelect: (item?: T) => void;
  onClear?: () => void;
  // Settings
  clearDisabled?: boolean;
  loading?: boolean;
  showItemsOnClick?: boolean;
  // Custom Behavior
  filterFn?: (items: T[], search: string) => T[];
  setItemLabel?: (item: T) => string;
  unlimitedList?: boolean;
  onFocus?: () => void;
}

export const startsWithFilter = (
  query: string,
  optionValue: Item['value'],
  optionLabel: Item['label']
): boolean => {
  const lowerCaseQuery = query.toLowerCase();
  const lowerCaseOptionValue = optionValue.toLowerCase();
  const lowerCaseOptionLabel = optionLabel.toLowerCase();
  return (
    lowerCaseOptionValue.includes(lowerCaseQuery) ||
    lowerCaseOptionLabel.includes(lowerCaseQuery)
  );
};

const genericLabel = <T,>(item: T) => {
  return JSON.stringify(item);
};

// <T,> required due to JSX syntax
export const Dropdown = <T,>({
  accessibilityLabel,
  placeholder,
  items,
  value,
  renderItem,
  onItemSelect,
  onClear,
  loading,
  setItemLabel = genericLabel,
}: DropdownProps<T>) => {
  return (
    <Flex justify="center" align="center" w="full">
      <AutoComplete openOnFocus isLoading={loading} filter={startsWithFilter}>
        {({ isOpen }) => (
          <>
            <InputGroup>
              <InputLeftElement paddingX={4}>
                <Icon as={FiSearch} color="#8e36ff" />
              </InputLeftElement>
              <AutoCompleteInput
                placeholder={placeholder}
                aria-label={accessibilityLabel || ''}
                value={value ? setItemLabel(value) : ''}
              />
              <InputRightElement>
                {value && onClear ? (
                  <IconButton
                    icon={<FiX />}
                    onClick={onClear}
                    aria-label={'dropdown clear button'}
                    backgroundColor={'transparent'}
                    borderWidth={0}
                  />
                ) : (
                  <Icon
                    as={value ? FiX : isOpen ? FiChevronRight : FiChevronDown}
                  />
                )}
              </InputRightElement>
            </InputGroup>
            <AutoCompleteList>
              {items.map((item, index) => (
                <AutoCompleteItem
                  key={`option-${index}`}
                  value={setItemLabel(item)}
                  textTransform="capitalize"
                  onClick={() => {
                    onItemSelect(item);
                  }}
                >
                  {renderItem(item)}
                </AutoCompleteItem>
              ))}
            </AutoCompleteList>
          </>
        )}
      </AutoComplete>
    </Flex>
  );
};
