import { useQuery } from '@tanstack/react-query';
import { getActiveSeasons } from 'api/futures';
import { type ActiveLeagueSeasonInfo } from 'types/sportsdata';

export function useSeasons() {
  const queryResponse = useQuery<ActiveLeagueSeasonInfo>({
    queryKey: ['activeSeasons'],
    queryFn: async () => await getActiveSeasons(),
  });

  return {
    activeSeasonInfo: queryResponse.data,
    activeSeasonInfoLoading: queryResponse.isLoading,
    activeSeasonInfoError: queryResponse.isError,
  };
}
