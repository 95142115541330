import { type Dispatch, type SetStateAction, useEffect } from 'react';

import 'screens/AddHotTake/style.css';
import {
  Box,
  Button as ChakraButton,
  Editable,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  EditableInput,
  EditablePreview,
} from '@chakra-ui/react';

export type PrizeInfo = {
  userMax: string;
  pot: string;
  potAmounts: string[];
};

export type PrizeScalingTableProps = {
  prizeInfo: PrizeInfo[];
  setPrizeInfo: Dispatch<SetStateAction<PrizeInfo[]>>;
  numberOfWinners: string;
};

export const PrizeScalingTable = ({
  prizeInfo,
  setPrizeInfo,
  numberOfWinners,
}: PrizeScalingTableProps) => {
  useEffect(() => {
    if (Number(numberOfWinners)) {
      // if the number of winners increases, add their prize amount as 0
      const newPrizeInfo = [...prizeInfo];
      prizeInfo.map((prizeInfoRow, index) => {
        [...Array(Number(numberOfWinners))].map((_, index4) => {
          if (prizeInfoRow.potAmounts[index4] == undefined) {
            newPrizeInfo[index].potAmounts.push('0');
          }
        });
        // if the number of winners decreases, remove that prize amount
        newPrizeInfo[index].potAmounts = prizeInfoRow.potAmounts.slice(
          0,
          Number(numberOfWinners)
        );
      });
      setPrizeInfo(newPrizeInfo);
    }
  }, [numberOfWinners]);

  return (
    <Box bg="light" p={8}>
      <Table
        size="sm"
        style={{
          borderCollapse: 'separate',
          borderSpacing: '0 10px',
        }}
      >
        <Thead w="full" position="inherit">
          <Tr w="full" display="flex" flexDirection="row">
            <Th
              color="text.secondary"
              border="none"
              textTransform="none"
              whiteSpace="nowrap"
              w="150px"
            >
              People
            </Th>
            <Th
              color="text.secondary"
              border="none"
              textTransform="none"
              whiteSpace="nowrap"
              w="150px"
            >
              Pot
            </Th>
            <Th
              color="text.secondary"
              border="none"
              textTransform="none"
              whiteSpace="nowrap"
              w="500px"
            >
              % Breakdown
            </Th>
            <Th
              color="text.secondary"
              border="none"
              textTransform="none"
              whiteSpace="nowrap"
              w="500px"
            >
              Prizes
            </Th>
          </Tr>
        </Thead>
        <Tbody>
          {prizeInfo.map((prizeInfoRow, index) => (
            <Tr key={index} w="full" display="flex" flexDirection="row">
              <Td
                display="flex"
                flexDirection="row"
                alignItems={'center'}
                w="150px"
              >
                {index == 0 ? 0 : Number(prizeInfo[index - 1].userMax) + 1}-
                <Editable
                  onChange={(e) => {
                    const newPrizeInfo = [...prizeInfo];
                    newPrizeInfo[index].userMax = e;
                    setPrizeInfo(newPrizeInfo);
                  }}
                  textAlign="start"
                  defaultValue={prizeInfoRow.userMax}
                >
                  <EditablePreview />
                  <EditableInput />
                </Editable>
              </Td>
              <Td w="150px">
                <Editable
                  onChange={(e) => {
                    const newPrizeInfo = [...prizeInfo];
                    newPrizeInfo[index].pot = e;
                    setPrizeInfo(newPrizeInfo);
                  }}
                  textAlign="start"
                  defaultValue={prizeInfoRow.pot}
                >
                  <EditablePreview />
                  <EditableInput />
                </Editable>
              </Td>
              <Td display="flex" flexDirection="row" w="500px">
                {prizeInfoRow.potAmounts.map((potAmount, index2) => {
                  return (
                    `${index2 > 0 ? ' | ' : ''}` +
                    `${
                      Math.floor(
                        (Number(potAmount) / Number(prizeInfoRow.pot)) * 10000
                      ) / 100
                    }%`
                  );
                })}
              </Td>
              <Td display="flex" flexDirection="row" w="500px">
                {prizeInfoRow.potAmounts.map((potAmount, index3) => (
                  <Box
                    display="flex"
                    flexDirection="row"
                    justifyContent={'center'}
                    alignItems={'center'}
                    key={index3}
                  >
                    $
                    <Editable
                      onChange={(e) => {
                        const newPrizeInfo = [...prizeInfo];
                        newPrizeInfo[index].potAmounts[index3] = e;
                        setPrizeInfo(newPrizeInfo);
                      }}
                      textAlign="left"
                      defaultValue={(
                        Math.floor(Number(potAmount) * 100) / 100
                      ).toString()}
                      width={10}
                    >
                      <EditablePreview />
                      <EditableInput />
                    </Editable>
                  </Box>
                ))}
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
      <ChakraButton
        onClick={() => {
          const newPrizeInfo = [...prizeInfo];
          const lastPrizeInfoRow = prizeInfo[prizeInfo.length - 1];
          newPrizeInfo.push({
            userMax: (Number(lastPrizeInfoRow.userMax) + 100).toString(),
            pot: (
              Number(lastPrizeInfoRow.pot) +
              10 * Number(numberOfWinners)
            ).toString(),
            potAmounts: lastPrizeInfoRow.potAmounts.map((p) =>
              (Number(p) + 10).toString()
            ),
          });
          setPrizeInfo(newPrizeInfo);
        }}
        mt={4}
        style={{ marginBottom: 8 }}
      >
        Add another row
      </ChakraButton>
      <ChakraButton
        onClick={() => {
          const newPrizeInfo = [...prizeInfo];
          newPrizeInfo.pop();
          setPrizeInfo(newPrizeInfo);
        }}
        mt={4}
        style={{ marginBottom: 8 }}
      >
        Remove row
      </ChakraButton>
    </Box>
  );
};

export default PrizeScalingTable;
