import { Box } from '@chakra-ui/react';
import { FaPlus } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { CustomButton } from 'components/CustomButton';

export const Futures = () => {
  const navigate = useNavigate();

  return (
    <Box className="futures-page">
      <Box
        as="header"
        bg="light"
        border="1px solid #d0ccd9"
        p="40px 64px 24px 64px"
        boxShadow="0 10px 25px 0 rgba(0, 0, 0, 0.1)"
        display="flex"
        flexDirection="column"
      >
        <Box as="h1" fontSize="48px" lineHeight="58px" fontWeight="600">
          Futures
        </Box>
        <Box as="p" mt={2}>
          View futures users have predicted on and finish futures that are done.
        </Box>
      </Box>
      <Box as="section" p="32px 64px 100px 64px" mt={6}>
        <Box display="flex" justifyContent="flex-end" mb={4}>
          <CustomButton
            accessibilityLabel="finish-future-button"
            buttonText="Finish Future"
            icon={<FaPlus />}
            onClick={() => navigate('/finishFuture')}
          />
        </Box>
      </Box>
    </Box>
  );
};
