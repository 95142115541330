import { useQuery } from '@tanstack/react-query';
import { getFuturesByLeague } from 'api/futures';
import { type MetricOption } from 'types/addPrediction';
import { type EntityType } from 'types/futures';
import { type League } from 'types/sportsdata';

export function useFutures(entityType: EntityType, league?: League) {
  const queryResponse = useQuery<MetricOption[]>({
    queryKey: ['futures', league, entityType],
    queryFn: async () => await getFuturesByLeague(league || 'nfl', entityType),
    enabled: !!league && !!entityType,
  });

  return {
    futures: queryResponse.data,
    futuresLoading: queryResponse.isLoading,
    futuresError: queryResponse.isError,
  };
}
