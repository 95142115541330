import {
  GAME_PRED_TYPES,
  getComparisonString,
  getMetricString,
  isCompareChildPred,
  isCompareParentPred,
  isComparisonPred,
  isFuturePred,
  isStatisticPred,
  SEASON_PRED_TYPES,
} from './prediction';
import {
  type GameHotTake,
  type HotTake,
  type SeasonHotTake,
} from 'types/hotTakes';
import {
  type FuturePredictionContent,
  type OutcomePredictionContent,
  type StatisticPredictionContent,
} from 'types/prediction';

export const isGameHotTake = (hotTake: HotTake): hotTake is GameHotTake => {
  return GAME_PRED_TYPES.includes(hotTake.predictionType);
};

export const isSeasonHotTake = (hotTake: HotTake): hotTake is SeasonHotTake => {
  return SEASON_PRED_TYPES.includes(hotTake.predictionType);
};

export const getEntityStringFromHotTake = (hotTake: HotTake) => {
  switch (hotTake.predictionType) {
    case 'PlayerGameStatistic':
    case 'PlayerSeasonStatistic':
      return (hotTake.content as StatisticPredictionContent).player;
    case 'TeamGameStatistic':
    case 'TeamSeasonStatistic':
      return (hotTake.content as StatisticPredictionContent).team;
    case 'TeamGameOutcome':
    case 'TeamSeasonOutcome':
      return (hotTake.content as OutcomePredictionContent).teamInfo.name;
    case 'PlayerSeasonFuture': {
      const playerInfo = (hotTake.content as FuturePredictionContent)
        .playerInfo;
      return `${playerInfo?.firstName} ${playerInfo?.lastName}`;
    }
    case 'TeamSeasonFuture': {
      const teamInfo = (hotTake.content as FuturePredictionContent).teamInfo;
      return `${teamInfo?.city || ''} ${teamInfo?.name}`.trim();
    }
  }
};

export const getMetricFromHotTake = (hotTake: HotTake) => {
  if (isFuturePred(hotTake.content, hotTake.predictionType)) {
    return getMetricString(hotTake.content.futureId.split('#')[2]);
  }
  if (!isCompareParentPred(hotTake.content, hotTake.predictionType)) {
    if (
      isStatisticPred(hotTake.content, hotTake.predictionType) ||
      isCompareChildPred(hotTake.content, hotTake.predictionType)
    ) {
      return getMetricString(hotTake.content.metric, hotTake.content.perGame);
    }
    return getMetricString(hotTake.content.metric);
  }
  return '';
};

export const getComparisonFromHotTake = (hotTake: HotTake) => {
  if (isComparisonPred(hotTake.content, hotTake.predictionType)) {
    return getComparisonString(hotTake.content.comparison);
  }
  if (isCompareParentPred(hotTake.content, hotTake.predictionType)) {
    return hotTake.content.comparison;
  }
  return '';
};

export const getPredictedValueFromHotTake = (hotTake: HotTake) => {
  if (
    !isFuturePred(hotTake.content, hotTake.predictionType) &&
    !(
      isCompareParentPred(hotTake.content, hotTake.predictionType) ||
      isCompareChildPred(hotTake.content, hotTake.predictionType)
    )
  ) {
    return hotTake.content.predictedValue as string;
  }
  return '';
};
