import { Navigate, Route, Routes } from 'react-router-dom';
import { RequireAdmin } from './RequireAdmin';
import { useAuth } from 'contexts/AuthContext';
import { AddHotTake } from 'screens/AddHotTake';
import { AddPool } from 'screens/AddPool';
import { AddRound } from 'screens/AddPool/AddRound';
import { AddPrediction } from 'screens/AddPrediction';
import { AddTournament } from 'screens/AddTournament';
import { AmbassadorTracking } from 'screens/AmbassadorTracking';
import { Announcements } from 'screens/Announcements';
import { ChallengeAllStats } from 'screens/ChallengeAllStats';
import { Communities } from 'screens/Communities';
import { CommunityDetails } from 'screens/CommunityDetails';
import CreateCommunity from 'screens/CreateCommunity';
import { FinishFuture } from 'screens/FinishFuture';
import { Futures } from 'screens/Futures';
import { HotTakes } from 'screens/HotTakes';
import { LoginPage } from 'screens/Login';
import { NotFoundPage } from 'screens/NotFound';
import { PoolDetails } from 'screens/PoolDetails';
import { Pools } from 'screens/Pools';
import PoolTemplateDetails from 'screens/PoolTemplateDetails';
import PoolTemplates from 'screens/PoolTemplates';
import { TournamentDetails } from 'screens/TournamentDetails';
import { Tournaments } from 'screens/Tournaments';
import { Unauthorized } from 'screens/Unauthorized';
import { UserMetrics } from 'screens/UserMetrics';

export const Router = () => {
  const { firebaseUser, loading } = useAuth();

  if (!loading && !firebaseUser) {
    return (
      <Routes>
        <Route path="/login" element={<LoginPage />} />
        <Route path="*" element={<Navigate replace to="/login" />} />
      </Routes>
    );
  }

  return (
    <Routes>
      {/*
        Both of these routes go to the login page since they do the same thing.
        Only the submit button text will change
      */}
      <Route path="/signup" element={<Navigate replace to="/viewHotTakes" />} />
      <Route path="/login" element={<Navigate replace to="/viewHotTakes" />} />
      <Route
        path="/viewHotTakes"
        element={
          <RequireAdmin reroutePath={'/unauthorized'} element={<HotTakes />} />
        }
      />
      <Route
        path="/addHotTake"
        element={
          <RequireAdmin
            reroutePath={'/unauthorized'}
            element={<AddHotTake />}
          />
        }
      />
      <Route
        path="/addChallenge"
        element={
          <RequireAdmin
            reroutePath={'/unauthorized'}
            element={<AddPrediction type="ChallengeAll" />}
          />
        }
      />
      <Route
        path="/viewChallengeAllStats"
        element={
          <RequireAdmin
            reroutePath={'/unauthorized'}
            element={<ChallengeAllStats />}
          />
        }
      />
      <Route
        path="/addPool"
        element={
          <RequireAdmin reroutePath={'/unauthorized'} element={<AddPool />} />
        }
      />
      <Route
        path="/addRound/:tournamentIdentifier/:currentRound/:maxParticipants/:authorUserId"
        element={
          <RequireAdmin reroutePath={'/unauthorized'} element={<AddRound />} />
        }
      />
      <Route
        path="/addTournament"
        element={
          <RequireAdmin
            reroutePath={'/unauthorized'}
            element={<AddTournament />}
          />
        }
      />
      <Route
        path="/viewPools"
        element={
          <RequireAdmin reroutePath={'/unauthorized'} element={<Pools />} />
        }
      />
      <Route
        path="/viewPoolTemplates"
        element={
          <RequireAdmin
            reroutePath={'/unauthorized'}
            element={<PoolTemplates />}
          />
        }
      />
      <Route
        path="/viewTournaments"
        element={
          <RequireAdmin
            reroutePath={'/unauthorized'}
            element={<Tournaments />}
          />
        }
      />
      <Route
        path="/userMetrics"
        element={
          <RequireAdmin
            reroutePath={'/unauthorized'}
            element={<UserMetrics />}
          />
        }
      />
      <Route
        path="/ambassadorTracking"
        element={
          <RequireAdmin
            reroutePath={'/unauthorized'}
            element={<AmbassadorTracking />}
          />
        }
      />
      <Route
        path="/announcements"
        element={
          <RequireAdmin
            reroutePath={'/unauthorized'}
            element={<Announcements />}
          />
        }
      />
      <Route
        path="/futures"
        element={
          <RequireAdmin reroutePath={'/unauthorized'} element={<Futures />} />
        }
      />
      <Route
        path="/finishFuture"
        element={
          <RequireAdmin
            reroutePath={'/unauthorized'}
            element={<FinishFuture />}
          />
        }
      />
      <Route path="/pool/:poolIdentifier" element={<PoolDetails />} />
      <Route
        path="/tournament/:tournamentIdentifier"
        element={<TournamentDetails />}
      />
      <Route
        path="/poolTemplate/:poolTemplateIdentifier"
        element={<PoolTemplateDetails />}
      />
      <Route
        path="/communities"
        element={
          <RequireAdmin
            reroutePath={'/unauthorized'}
            element={<Communities />}
          />
        }
      />
      <Route path="/community/:communityId" element={<CommunityDetails />} />
      <Route path="/createCommunity" element={<CreateCommunity />} />
      <Route path="/unauthorized" element={<Unauthorized />} />
      <Route path="/" element={<Navigate replace to="/viewHotTakes" />} />
      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  );
};
