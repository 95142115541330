import { useState } from 'react';

import './style.css';
import { useNavigate } from 'react-router-dom';
import { postTournament } from 'api/tournaments';
import CommunitySearch from 'chakraComponents/CommunitySearch';
import { DateInput } from 'components/AddPredictionForm/components/DateInput';
import { Button } from 'components/Button';
import { Input } from 'components/Input';
import PrizeScalingTable, {
  type PrizeInfo,
} from 'components/PrizeScalingTable';
import { UserSearch } from 'components/UserSearch';
import { VLTED_USER_SUMMARY } from 'constants/.';
import { type Community } from 'types/community';
import { type PostTournament } from 'types/tournaments';
import { type UserSummary } from 'types/user';

export const AddTournament = () => {
  const navigate = useNavigate();

  // Page Status
  const [placingTournament, setPlacingTournament] = useState(false);

  // Tournament Content
  const [tournamentTitle, setTournamentTitle] = useState<string>('');
  const [tournamentGoLiveTime, setTournamentGoLiveTime] = useState<Date>(
    new Date(Date.now())
  );
  const [tournamentCloseTime, setTournamentCloseTime] = useState<Date>(
    new Date(Date.now())
  );
  const [numberOfRounds, setNumberOfRounds] = useState<string>('1');
  const [chosenAuthor, setChosenAuthor] = useState<UserSummary | undefined>(
    VLTED_USER_SUMMARY
  );
  const [isPromotional, setIsPromotional] = useState(false);
  const [termsLink, setTermsLink] = useState<string>('');
  const [rewardString, setRewardString] = useState<string>('');
  const [numberOfWinners, setNumberOfWinners] = useState<string>('8');
  const [prizeInfo, setPrizeInfo] = useState<PrizeInfo[]>([
    {
      userMax: '8',
      pot: '25',
      potAmounts: ['25', '0', '0', '0', '0', '0', '0', '0'],
    },
    {
      userMax: '16',
      pot: '100',
      potAmounts: ['100', '0', '0', '0', '0', '0', '0', '0'],
    },
    {
      userMax: '32',
      pot: '150',
      potAmounts: ['150', '0', '0', '0', '0', '0', '0', '0'],
    },
    {
      userMax: '64',
      pot: '300',
      potAmounts: ['250', '50', '0', '0', '0', '0', '0', '0'],
    },
    {
      userMax: '128',
      pot: '500',
      potAmounts: ['400', '50', '25', '25', '0', '0', '0', '0'],
    },
    {
      userMax: '1000',
      pot: '740',
      potAmounts: ['500', '100', '50', '50', '10', '10', '10', '10'],
    },
  ]);
  const [isHidden, setIsHidden] = useState(false);
  const [communities, setCommunities] = useState<Community[] | undefined>(
    undefined
  );
  const [prependCmntyName, setPrependCmntyName] = useState(false);

  const isPromotionalTournament = () => {
    return isPromotional && termsLink && rewardString;
  };

  const winningsValid = () => {
    if (isPromotionalTournament()) {
      for (const prizeInfoRow of prizeInfo) {
        if (!Number.isInteger(Number(prizeInfoRow.userMax))) {
          return false;
        }
        if (!Number(prizeInfoRow.pot)) {
          return false;
        }
        if (prizeInfoRow.potAmounts.length != Number(numberOfWinners)) {
          return false;
        }
        let totalWinnings = 0;
        for (const prizeAmounts of prizeInfoRow.potAmounts) {
          totalWinnings += Number(prizeAmounts);
        }
        if (totalWinnings != Number(prizeInfoRow.pot)) {
          return false;
        }
      }
    }
    return true;
  };

  const submitTournamentEnabled = () => {
    return (
      tournamentGoLiveTime &&
      tournamentCloseTime &&
      tournamentTitle &&
      chosenAuthor &&
      Number(numberOfRounds) &&
      (isPromotionalTournament() || !isPromotional) &&
      winningsValid()
    );
  };

  const submitTournament = async () => {
    if (!chosenAuthor) {
      console.error('Error adding tournament: no author chosen for tournament');
      return;
    }
    const postBody: PostTournament = {
      tournamentTitle: tournamentTitle,
      numberOfRounds: Number(numberOfRounds),
      tournamentGoLiveTime: tournamentGoLiveTime,
      tournamentCloseTime: tournamentCloseTime,
      authorUserId: chosenAuthor.userId,
      privacyStatus: isHidden ? 'hidden' : 'public',
      communities: communities,
      prependCommunityName: prependCmntyName,
    };
    const prizeWinningsMap = new Map(
      prizeInfo.map((val) => [
        val.userMax,
        val.potAmounts
          .filter((potAmount) => Number(potAmount) > 0)
          .map((potAmount) => Number(potAmount)),
      ])
    );
    if (isPromotionalTournament()) {
      postBody.promoScalingInfo = {
        rewardString,
        termsLink,
        prizeWinnings: Object.fromEntries(prizeWinningsMap),
      };
    }

    // TODO[#56]: Optimistic Mutation?
    setPlacingTournament(true);
    try {
      await postTournament(postBody);
    } catch (err) {
      console.error('Error adding tournament.', err);
    }
    // TODO[#57]: Invalidate Tournament query once view tournaments its completed.
    setPlacingTournament(false);
    navigate('/viewTournaments');
  };

  const clearAllFields = async () => {
    console.error('Should clear');
  };

  return (
    <div className="add-tournament-container">
      <h2>Add Tournament</h2>
      <div>
        <p>Tournament Title</p>
        <Input
          id={'Tournament title field'}
          accessibilityLabel={'Tournament title input'}
          value={tournamentTitle}
          handleOnChange={setTournamentTitle}
        />
      </div>
      <div>
        <p>Tournament Go Live Time</p>
        <DateInput
          date={tournamentGoLiveTime}
          setDate={setTournamentGoLiveTime}
          disabled={placingTournament}
          showTime={true}
        />
      </div>
      <div>
        <p>Tournament Close Time</p>
        <DateInput
          date={tournamentCloseTime}
          setDate={setTournamentCloseTime}
          disabled={placingTournament}
          showTime={true}
        />
      </div>
      <div>
        <p>Number of Rounds in Tournament</p>
        <Input
          id={'Tournament rounds field'}
          accessibilityLabel={'Tournament max rounds input'}
          value={numberOfRounds}
          placeholder={typeof numberOfRounds == 'string' ? numberOfRounds : ''}
          handleOnChange={setNumberOfRounds}
        />
      </div>
      <div>
        <p>Tournament Author</p>
        <UserSearch
          chosenAuthor={chosenAuthor}
          setChosenAuthor={setChosenAuthor}
        />
        {chosenAuthor && <p></p>}
      </div>
      <div>
        <label>
          Is this a promotional tournament?
          <input
            type="checkbox"
            aria-label={'Tournament promotion checkbox'}
            checked={isPromotional}
            onChange={() => {
              setIsPromotional(!isPromotional);
            }}
          />
          {isPromotional && (
            <div>
              <div>
                <p>Terms Link</p>
                <Input
                  id={'Tournament promotion terms link field'}
                  accessibilityLabel={'Promotion terms link input'}
                  value={termsLink}
                  handleOnChange={setTermsLink}
                />
              </div>
              <div>
                <p>Reward String</p>
                <Input
                  id={'Pool promotion reward string field'}
                  accessibilityLabel={'Promotion reward input'}
                  value={rewardString}
                  handleOnChange={setRewardString}
                />
              </div>
              <div>
                <p>Number of Winners</p>
                <Input
                  id={'Tournament number of winners field'}
                  accessibilityLabel={'Tournament number of winners input'}
                  value={numberOfWinners}
                  placeholder={
                    typeof numberOfWinners == 'string' ? numberOfWinners : ''
                  }
                  handleOnChange={setNumberOfWinners}
                />
              </div>
              <PrizeScalingTable
                prizeInfo={prizeInfo}
                setPrizeInfo={setPrizeInfo}
                numberOfWinners={numberOfWinners}
              />
            </div>
          )}
        </label>
      </div>
      <div>
        <label>
          Is this a hidden tournament?
          <input
            type="checkbox"
            aria-label={'Tournament privacy checkbox'}
            checked={isHidden}
            onChange={() => {
              setIsHidden(!isHidden);
            }}
          />
        </label>
      </div>
      {isHidden && (
        <div className="tournament-communities-container">
          <div>
            <p>Communities to attach this tournament to (optional):</p>
            <CommunitySearch
              multiSelect={true}
              handleOnClick={(communities) => {
                setCommunities(communities);
              }}
            />
          </div>
          <div>
            <p>Communities attached:</p>
            {communities?.map((community, index) => (
              <p key={index}>{community.title}</p>
            ))}
          </div>
          <label>
            Prepend community names to pool title?
            <input
              type="checkbox"
              aria-label={'Prepend community name checkbox'}
              checked={prependCmntyName}
              onChange={() => {
                setPrependCmntyName(!prependCmntyName);
              }}
            />
          </label>
        </div>
      )}
      <Button
        accessibilityLabel="Create Tournament Button"
        handleOnClick={() => {
          setPlacingTournament(true);
          submitTournament();
          clearAllFields();
        }}
        type="primary"
        text="Create Tournament"
        disabled={!submitTournamentEnabled() || placingTournament}
        loading={placingTournament}
      />
    </div>
  );
};

export default AddTournament;
