import {
  Flex,
  Icon,
  InputGroup,
  InputLeftElement,
  InputRightElement,
} from '@chakra-ui/react';
import {
  AutoComplete,
  AutoCompleteInput,
  AutoCompleteItem,
  AutoCompleteList,
  AutoCompleteTag,
  type ItemTag,
} from '@choc-ui/chakra-autocomplete';
import { FiChevronRight, FiChevronDown, FiSearch } from 'react-icons/fi';
import { startsWithFilter } from './Dropdown';

interface MultiselectDropdownProps<T> {
  accessibilityLabel?: string;
  placeholder: string;
  items: T[];
  renderItem: (item: T) => JSX.Element;
  onItemSelect: (item?: T) => void;
  onItemDeselect?: (item?: ItemTag) => void;
  loading?: boolean;
  setItemLabel: (item: T) => string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  inputRef: React.MutableRefObject<any>;
}

// <T,> required due to JSX syntax
export const MultiselectDropdown = <T,>({
  accessibilityLabel,
  placeholder,
  items,
  renderItem,
  onItemSelect,
  onItemDeselect,
  loading,
  setItemLabel,
  inputRef,
}: MultiselectDropdownProps<T>) => {
  return (
    <Flex justify="center" align="center" w="full">
      <AutoComplete
        openOnFocus
        isLoading={loading}
        filter={startsWithFilter}
        ref={inputRef}
        multiple
      >
        {({ isOpen }) => (
          <>
            <InputGroup>
              <InputLeftElement paddingX={4}>
                <Icon as={FiSearch} color="#8e36ff" />
              </InputLeftElement>
              <AutoCompleteInput
                placeholder={placeholder}
                aria-label={accessibilityLabel || ''}
              >
                {({ tags }) =>
                  tags.map((tag, index) => (
                    <AutoCompleteTag
                      marginLeft={index == 0 ? 4 : 0}
                      key={index}
                      label={tag.label}
                      onRemove={() => {
                        tag.onRemove();
                        if (onItemDeselect) {
                          onItemDeselect(tag);
                        }
                      }}
                    />
                  ))
                }
              </AutoCompleteInput>
              <InputRightElement>
                <Icon as={isOpen ? FiChevronRight : FiChevronDown} />
              </InputRightElement>
            </InputGroup>
            <AutoCompleteList>
              {items.map((item, index) => (
                <AutoCompleteItem
                  key={`option-${index}`}
                  value={setItemLabel(item)}
                  textTransform="capitalize"
                  onClick={() => {
                    onItemSelect(item);
                  }}
                >
                  {renderItem(item)}
                </AutoCompleteItem>
              ))}
            </AutoCompleteList>
          </>
        )}
      </AutoComplete>
    </Flex>
  );
};
