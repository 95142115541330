import {
  Box,
  Heading,
  HStack,
  Icon,
  Image,
  VStack,
  Text,
} from '@chakra-ui/react';
import { FaFire } from 'react-icons/fa';
import { LuWaves } from 'react-icons/lu';
import { LuMegaphone } from 'react-icons/lu';
import { MdOutlineLocationCity } from 'react-icons/md';
import { RiSwordLine } from 'react-icons/ri';
import { RiNumbersLine } from 'react-icons/ri';
import { SlBadge } from 'react-icons/sl';
import { TbTournament } from 'react-icons/tb';
import { TbLogout2 } from 'react-icons/tb';
import { TbCrystalBall } from 'react-icons/tb';
import {
  Menu,
  MenuItem,
  Sidebar,
  SubMenu,
  type MenuItemStyles,
} from 'react-pro-sidebar';
import {
  NavLink,
  useNavigate,
  type NavLinkRenderProps,
} from 'react-router-dom';
import { VLTEDIcon } from 'assets';
import { Accents, Neutral, Text as TextColor } from 'chakra/colors';
import { EnvironmentMenu } from 'components/SidebarNav/environment-menu';
import { useAuth } from 'contexts/AuthContext';

export const SidebarNav: React.FC = () => {
  const auth = useAuth();
  const navigate = useNavigate();

  const menuItemStyles: MenuItemStyles = {
    root: {
      fontSize: '14px',
      fontWeight: 500,
      color: TextColor.primary,
      fontFamily: 'Inter',
      '&:hover': {
        backgroundColor: Accents.p2,
      },
      '&:active': {
        backgroundColor: Accents.p4,
      },
    },
    icon: {
      color: Accents.p1,
    },
    button: () => ({
      width: '100%',
      '&:hover': {
        backgroundColor: Accents.p2,
      },
      '&:active': {
        backgroundColor: Accents.p4,
      },
    }),
    label: ({ open }) => ({
      fontWeight: open ? 600 : undefined,
    }),
    subMenuContent: {
      backgroundColor: Neutral.n800,
    },
  };

  const iconSize = '24px';
  const navLinkStyles: {
    style: (props: NavLinkRenderProps) => React.CSSProperties;
  } = {
    style: ({ isActive }) => ({
      backgroundColor: isActive ? Accents.p3 : 'transparent',
    }),
  };

  return (
    <Box h="100%" bg="neutral.500">
      <Sidebar
        collapsed={false}
        backgroundColor={Neutral.n500}
        width="100%"
        id="nav-bar"
      >
        <VStack h="100vh" w="100%" align="flex-start" justify="space-between">
          <Box>
            <HStack w="100%" mt="14px" gap="12px">
              <Image
                src={VLTEDIcon}
                alt="VLTED Icon"
                boxSize="50px"
                m="0"
                ml="14px"
              />
              <Heading color="text.primary" size="sm">
                Admin Console
              </Heading>
            </HStack>
            <Box w="100%" mt="12px">
              <Text variant="eyebrow" fontSize="11px" pl="8px">
                Environment
              </Text>
              <EnvironmentMenu />
            </Box>
            <Box mt="32px">
              <Text variant="eyebrow" fontSize="11px" pl="8px">
                Menu
              </Text>
              <Menu menuItemStyles={menuItemStyles}>
                <MenuItem
                  component={
                    <NavLink to={'/viewHotTakes'} {...navLinkStyles} />
                  }
                  icon={<Icon as={FaFire} boxSize={iconSize} />}
                >
                  Hot Takes
                </MenuItem>
                <SubMenu
                  label="Challenges"
                  icon={<Icon as={RiSwordLine} boxSize={iconSize} />}
                >
                  <MenuItem
                    component={
                      <NavLink to={'/addChallenge'} {...navLinkStyles} />
                    }
                  >
                    Challenge EVERYBODY
                  </MenuItem>
                  <MenuItem
                    component={
                      <NavLink
                        to={'/viewChallengeAllStats'}
                        {...navLinkStyles}
                      />
                    }
                  >
                    Challenge All Stats
                  </MenuItem>
                </SubMenu>
                <SubMenu
                  label="Pools"
                  icon={<Icon as={LuWaves} boxSize={iconSize} />}
                >
                  <MenuItem
                    component={<NavLink to={'/viewPools'} {...navLinkStyles} />}
                  >
                    Pools
                  </MenuItem>
                  <MenuItem
                    component={
                      <NavLink to={'/viewPoolTemplates'} {...navLinkStyles} />
                    }
                  >
                    Pool Templates
                  </MenuItem>
                </SubMenu>
                <MenuItem
                  component={
                    <NavLink to={'/viewTournaments'} {...navLinkStyles} />
                  }
                  icon={<Icon as={TbTournament} boxSize={iconSize} />}
                >
                  Tournaments
                </MenuItem>
                <MenuItem
                  component={<NavLink to={'/communities'} {...navLinkStyles} />}
                  icon={<Icon as={MdOutlineLocationCity} boxSize={iconSize} />}
                >
                  Communities
                </MenuItem>
                <MenuItem
                  component={<NavLink to={'/userMetrics'} {...navLinkStyles} />}
                  icon={<Icon as={RiNumbersLine} boxSize={iconSize} />}
                >
                  User Metrics
                </MenuItem>
                <MenuItem
                  component={
                    <NavLink to={'/announcements'} {...navLinkStyles} />
                  }
                  icon={<Icon as={LuMegaphone} boxSize={iconSize} />}
                >
                  Announcements
                </MenuItem>
                <MenuItem
                  component={
                    <NavLink to={'/ambassadorTracking'} {...navLinkStyles} />
                  }
                  icon={<Icon as={SlBadge} boxSize={iconSize} />}
                >
                  Ambassadors
                </MenuItem>
                <MenuItem
                  component={<NavLink to={'/futures'} {...navLinkStyles} />}
                  icon={<Icon as={TbCrystalBall} boxSize={iconSize} />}
                >
                  Futures
                </MenuItem>
              </Menu>
            </Box>
          </Box>
          <Box w="100%" mb="50px">
            <Text variant="eyebrow" fontSize="11px" pl="8px" mt="40px">
              Account
            </Text>
            <Menu menuItemStyles={{ ...menuItemStyles }}>
              <MenuItem
                component={
                  <Box
                    onClick={() => auth.logout(() => navigate('/login'))}
                    verticalAlign="center"
                  />
                }
                icon={<Icon as={TbLogout2} boxSize={iconSize} />}
              >
                Logout
              </MenuItem>
            </Menu>
          </Box>
        </VStack>
      </Sidebar>
    </Box>
  );
};
